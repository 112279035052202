import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { ArrowDownOutlined, BankOutlined, CloseCircleOutlined, PhoneOutlined, FieldTimeOutlined, CheckCircleOutlined, SwapOutlined } from '@ant-design/icons';
import StatService from "../Services/StatService";

const DashBoardComponent = () => {

    const [stats, setStats] = useState(null);
    const fetch_stat = () => {
        StatService.get_stat_info().then((response) => { setStats(response); });
    };

    useEffect(() => {
        fetch_stat();
    }, []);


    return stats ? (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Card>
                        <Statistic
                            suffix={<PhoneOutlined />}
                            title="Appels"
                            valueStyle={{ color: '#3a00c9' }}
                            value={stats.total_calls_done + stats.total_calls_failed} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic
                            title="Appels terminés"
                            value={stats.total_calls_done}
                            valueStyle={{ color: '#00d56b' }}
                            prefix={<CheckCircleOutlined />}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic
                            title="Appels échoués"
                            value={stats.total_calls_failed}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<CloseCircleOutlined />}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic
                            title="Durée des appels terminés"
                            value={stats.completed_calls_duration}
                            valueStyle={{ color: '#00d56b' }}
                            prefix={<FieldTimeOutlined />}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic
                            title="Commission istishara"
                            value={stats.total_comission}
                            precision={2}
                            valueStyle={{ color: '#00d56b' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="TND"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic
                            title="Transactions monétiques"
                            value={stats.total_money_movement}
                            precision={2}
                            valueStyle={{ color: '#079cff' }}
                            prefix={<SwapOutlined />}
                            suffix="TND"
                        />
                    </Card>
                </Col>
                {/*
                
                     <Col span={8}>
                    <Card>
                        <Statistic
                            title="Consommation Twillio"
                            value={stats.twillio_consumption}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<BankOutlined />}
                        />
                    </Card>
                </Col>
                */}





            </Row>
        </div>
    ) : 'Loading...';
};

export default DashBoardComponent;
