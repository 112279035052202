import React from 'react';
import { Modal, Table, Tag} from "antd";
const generateDate = time =>
    new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        time.split(":")[0],
        time.split(":")[1],
        0
    );
const res = UTCString => {
    const result = new Date(
        UTCString.setMinutes(UTCString.getMinutes() + (2 * 60))
    );
    return result
}
const columns = [

    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: date => {
            let msg
            if (date) {
                const Date = date.split('T')[0]
                const Time = date.split('T')[1].split('.')[0].substring(0, 5)
                const UTCString = generateDate(Time);
                const readbleString = res(UTCString).toTimeString().split(" ")[0];
                msg = `le : ${Date} à: ${readbleString.substr(0, readbleString.length - 3)}`
            }
            return (
                <Tag color="blue" key={date}>
                    {msg}
                </Tag>
            )
        },
    },
    {
        title: "Montant",
        dataIndex: "amount",
        key: "amount",
        render: amount => {
            return `${amount} TND`
        },
    },
    {
        title: "Statut",
        dataIndex: "status",
        key: "status",
    }
];
const ProTransactionsHistory = ({ data, visible, openModal }) => {
    return (
        <div>
            <Modal
                title="Historique des transactions"
                centered
                visible={visible}
                onCancel={openModal}
                footer={[null]}
                width={1000}
                height={500}
            >

                {data && <Table columns={columns} dataSource={data.map((c, index) => {

                    return {
                        key: index,
                        date: c.date || '-',
                        status: c.status,
                        amount: c.amount || '-'
                    }
                })} />}
            </Modal>
        </div>
    );
};

export default ProTransactionsHistory;
