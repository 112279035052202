import axios from 'axios';

class CallService {
    async get_call_history(params){
 
        return await axios.get(`${process.env.REACT_APP_API_URL}backoffice/fetch_call_history_filtred`,{params})
            .then(({data}) => {
                return data.data;
            }).catch(error => {
                return error.response
            });
    }
}
export default new CallService();
