import axios from 'axios';

class ProfessionnelService {

    async get_all_professionnels(params) {
        return await axios.get(`${process.env.REACT_APP_API_URL}backoffice/filter_pro`, { params })
            .then((response) => {
                return response.data.data;
            }).catch(error => {
                return error.response
            });
    }

    async get_professionnel_call_history(id) {
        return await axios.get(`${process.env.REACT_APP_API_URL}backoffice/fetch_call_history?user_id=${id}`)
            .then(({ data }) => {
                return data.data;
            }).catch(error => {
                return error.response
            });
    }

    async fetch_professionnel_transfers(id) {
        return await axios.get(`${process.env.REACT_APP_API_URL}backoffice/fetch_transfers?to_user_id=${id}`)
            .then(({ data }) => {
                return data.data;
            }).catch(error => {
                return error.response
            });
    }

    async create_professionnel(pro) {
        return await axios.post(`${process.env.REACT_APP_API_URL}users/create`, pro)
            .then(({ data }) => {
                return data.data
            }).catch(error => {
                return error.response
            });
    }

    async update_professionnel(id, pro) {
        return await axios.put(`${process.env.REACT_APP_API_URL}users/update/${id}`, pro)
            .then(({ data }) => {
                return data.data
            }).catch(error => {
                return error.response
            });
    }

    async upload_Image(files) {
        return await axios.post(`${process.env.REACT_APP_API_URL}efile/uploadFile`, files)
            .then(({ data }) => {
                return data.data
            }).catch(error => {
                return error.response
            });
    }
    async notify_pro(id, values) {
        return await axios.post(`${process.env.REACT_APP_API_URL}backoffice/notify_pro?user_id=${id}`)
            .then(({ data }) => {
                return data.data
            }).catch(error => {
                return error.response
            });
    }
}
export default new ProfessionnelService()
