import React, { useEffect, useState } from 'react';
import TransactionService from "../Services/TransactionService";
import AllTransactionHistoryTable from "../Containers/TransactionHistory/AllTransactionHistoryTable";
import { Button, Card, Col, DatePicker, Input, Pagination, Select, Row } from "antd";
import moment from "moment";
const { Option } = Select;
const { Search } = Input;

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let params = { transaction_type: 'tous' };

const TransactionsHistoryComponent = () => {

    const [virements, setVirement] = useState([]);
    const [totalItems, setTotalItems] = useState(null);
    const [search, setSearch] = useState('');
    const fetch_all_virement = () => {
        TransactionService.get_transations_history(params).then((response) => {

            setVirement(response.fetch_Transactions);
            setTotalItems(response.countTotal)
        }).catch((e) => console.log(e.message))
    };
    const exportCSV = () => {
        TransactionService.export_transations_csv(params).then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'file.csv'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
    };
    const get_transations_history = () => {
        TransactionService.get_transations_history(params)
            .then((response) => {
                setVirement(response.fetch_Transactions);
                setTotalItems(response.countTotal)
            })
            .catch((e) => console.log(e.message));
    };
    useEffect(() => {
        params.page = 1;
        params.transaction_type = "tous";
        let d = new Date();
        // Set it to one month ago
        d.setMonth(d.getMonth() - 1);
        params.start_date = formatDate(d);
        params.end_date = formatDate(new Date());
        fetch_all_virement();
    }, []);

    const handleSearch = (value) => {
        params.page = 1;
        params.search = value;
        fetch_all_virement();
    };
    const clearFilter = () => {
        params.page = 1;
        params.transaction_type = "tous";
        let d = new Date();
        // Set it to one month ago
        d.setMonth(d.getMonth() - 1);
        params.start_date = formatDate(d);
        params.end_date = formatDate(new Date());
        delete params.search;
        setSearch('');
        fetch_all_virement();
    };
    return virements ? (
        <div>
            <Card title={'Filtre'}>
                <Row gutter={[16, 16]}>
                    <Col>
                        Date  : <RangePicker allowClear={false}
                            value={[moment(params.start_date, dateFormat), moment(params.end_date, dateFormat)]}
                            format={dateFormat}
                            onChange={(m, dateString) => {
                                params.start_date = dateString[0];
                                params.end_date = dateString[1];
                                fetch_all_virement()
                            }}
                            disabledDate={current => {
                                return current && current > moment()
                            }}
                        />
                    </Col>
                    <Col  >
                        transaction type: <Select
                            placeholder="Status..."
                            defaultValue={'tous'}
                            value={params.transaction_type}
                            onSelect={(e) => {
                                params.transaction_type = e; params.page = 1;
                                get_transations_history()
                            }}
                        >
                            <Option value={'tous'}>Tous</Option>
                            <Option value={'recharge'}>Recharge</Option>
                            <Option value={'payment-sub'}>Payment-sub</Option>
                            <Option value={'call'}>Call</Option>
                        </Select>
                    </Col>
                    <Col>
                        <Search placeholder="Rechercher client.." value={search} onChange={e => setSearch(e.target.value)}
                            enterButton onSearch={handleSearch} />
                    </Col>
                    <Col flex="auto">
                        <Button type={'primary'} onClick={() => exportCSV()}>Exporter en CSV</Button>
                    </Col>
                </Row>
                <Row justify={'end'} style={{ marginTop: 20 }}>
                    <Button onClick={() => clearFilter()}>Réinitialiser filtre</Button>
                </Row>
            </Card>
            <AllTransactionHistoryTable data={virements} />
            <Row style={{ paddingTop: 10 }} justify={'end'}>
                <Pagination defaultCurrent={1}
                    total={totalItems}
                    current={params.page}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50, 100]}
                    onShowSizeChange={(e, v) => {
                        params.limit = v;
                        get_transations_history();
                    }}
                    onChange={(e, v) => {
                        params.page = e;
                        get_transations_history()
                    }} />
            </Row>

        </div>
    ) : 'Loading...';
};

export default TransactionsHistoryComponent;
