import React from 'react';
import {Space, Table, Tooltip,} from 'antd';
import Icon from "@ant-design/icons";
import {ReactComponent as UpdateIcon} from "../../icons/refresh.svg";
import {ReactComponent as DeleteIcon} from "../../icons/trash.svg";
import {ReactComponent as subCategory} from "../../icons/subCategory.svg";


const TableTreeCategory = ({data, openUpdateModal, openAddSubModal, openDeleteModal}) => {

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'category_name',
            key: 'category_name',
        },
        {
            title: 'Description',
            dataIndex: 'category_description',
            key: 'category_description',
            width: '30%',
        },
        {
            title: 'Prix d\'inscription',
            dataIndex: 'annuel_sub_price',
            key: 'annuel_sub_price',
            render: annuel_sub_price => {
                return `${annuel_sub_price} TND`
            },
        },
        {
            title: 'Prix par seconde',
            dataIndex: 'price_per_sec',
            key: 'price_per_sec',
            render: price_per_sec => {
                return `${price_per_sec} TND/s`
            },
        },
        {
            title: 'Commission Istishara',
            dataIndex: 'istishara_commision',
            key: 'istishara_commision',
            render: istishara_commision => {
                return `${istishara_commision} %`
            },
        }, {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, value) => {
                return (
                    <Space>
                        {!value.parent_category_job_id && value.has_speciality && <Tooltip title="Ajouter une sous catégorie">
                            <Icon component={subCategory} onClick={() => openAddSubModal(value)}
                                  style={{fontSize: '32px'}}/>
                        </Tooltip>}
                        <Tooltip title="Mettre à jour">
                            <Icon component={UpdateIcon} onClick={() => openUpdateModal(value)}
                                  style={{fontSize: '32px'}}/>
                        </Tooltip>
                        <Tooltip title="Supprimer la catégorie">
                            <Icon component={DeleteIcon} onClick={() => openDeleteModal(value)}
                                  style={{fontSize: '32px'}}/>
                        </Tooltip>


                    </Space>
                )
            }
        },
    ];

    return (
        <div>
            <Table
                columns={columns}
                dataSource={data}
            />
        </div>
    );
};

export default TableTreeCategory;
