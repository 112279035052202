import React, { useEffect } from 'react';
import { Badge, Space, Table, Tag, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as UpdateIcon } from "../../icons/refresh.svg";
import { ReactComponent as CallIcon } from "../../icons/phone-call.svg";
import { ReactComponent as ViremIcon } from "../../icons/transaction.svg";

const ProfessionnelsTable = ({ data, update, call, transactions }) => {

    useEffect(() => {

    }, [data]);

    const generateDate = time =>
        new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            time.split(":")[0],
            time.split(":")[1],
            0
        );
    const res = UTCString => {
        const result = new Date(
            UTCString.setMinutes(UTCString.getMinutes() + (2 * 60))
        );
        return result
    }

    const columns = [
        {
            dataIndex: "active",
            key: "active",
            render: (text, value) => {
                return value.active === 'true' ? <Tooltip title="Active">
                    <Badge status="success" />
                </Tooltip> : <Tooltip title="Blocked">
                    <Badge status="error" />
                </Tooltip>
            }
        },
        {
            title: "Nom",
            dataIndex: "first_name",
            key: "first_name",

        },
        {
            title: "Prénom",
            dataIndex: "last_name",
            key: "last_name",

        },
        {
            title: "Télephone",
            dataIndex: "phone",
            key: "phone",
            sorter: (a, b) => a.phone - b.phone,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Total_available",
            dataIndex: "total_available",
            key: "total_available",
            render: (total_available, value) => {
                return (
                    <Space>
                        <Tooltip title="Mettre à jour">
                            {total_available} TND
                        </Tooltip>
                    </Space>
                )
            }
        },
        {
            title: "Total_win",
            dataIndex: "total_win_pro",
            key: "total_win_pro",
            render: (total_win_pro, value) => {
                return (
                    <Space>
                        <Tooltip title="Mettre à jour">
                            {total_win_pro} TND
                        </Tooltip>
                    </Space>
                )
            }
        },
        {
            title: "Catégorie",
            dataIndex: "category_name",
            key: "category_name",
            filters: [
                {
                    text: 'Conseil fiscal',
                    value: 'Conseil fiscal',
                },
                {
                    text: 'Professionnel Comptable',
                    value: 'Professionnel Comptable',
                },
                {
                    text: 'Avocat',
                    value: 'Avocat',
                }
            ],
            onFilter: (value, record) => record.category_name.indexOf(value) === 0,
        },
        {
            title: "Statut",
            dataIndex: "state_account",
            key: "state_account",
            render: tag => {
                let color = tag === "approuved" ? 'green' : tag === null ? '#ffccf1' : 'volcano';
                return (
                    <Tag color={color} key={tag}>
                        {tag === "approuved" ? "Approuvé" : tag === "not-approuved" ? "Rejeté" : 'En attente'}
                    </Tag>
                )
            },


        }
        ,
        {
            title: "Payé/non payé",
            dataIndex: "state_payment_signup",
            key: "state_payment_signup",
            render: tag => {
                let color = tag === "payed" ? 'green' : 'volcano';
                return (
                    <Tag color={color} key={tag}>
                        {tag === "payed" ? "payed" : "not-payed" }
                    </Tag>
                )
            },
        },
        {
            title: "Dernier Mise à jour",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: updatedAt => {
                let msg
                if (updatedAt) {
                    const Date = updatedAt.split('T')[0]
                    const Time = updatedAt.split('T')[1].split('.')[0].substring(0, 5)
                    const UTCString = generateDate(Time);
                    const readbleString = res(UTCString).toTimeString().split(" ")[0];
                    msg = `le : ${Date} à: ${readbleString.substr(0, readbleString.length - 3)}`
                }
                return (
                    <Tag color="blue" key={updatedAt}>
                        {msg}
                    </Tag>
                )
            },
        },
        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: (text, value) => {
               
                return (

                    <Space>
                        <Tooltip title="Mettre à jour">
                            <Icon component={UpdateIcon} onClick={() => update(value)} style={{ fontSize: '32px' }} />
                        </Tooltip>
                        <Tooltip title="Historique d'appel">
                            <Icon component={CallIcon} onClick={() => call(value)} style={{ fontSize: '32px' }} />
                        </Tooltip>
                        <Tooltip title="Transactions">
                            <Icon component={ViremIcon} onClick={() => transactions(value)} style={{ fontSize: '32px' }} />
                        </Tooltip>
                    </Space>
                )
            }
        },
    ];


    return (
        <div>
            <Table columns={columns} pagination={false} dataSource={data.map((p, index) => {
                return {
                    key: index,
                    first_name: p.first_name || '-',
                    last_name: p.last_name || '-',
                    phone: p.phone || '-',
                    email: p.email || '-',
                    state_account: p.state_account,
                    category_name: p['category.category_name'] || '-',
                    userId: p.user_id,
                    active: p.active,
                    num_cin: p.num_cin,
                    num_passport: p.num_passport,
                    description: p.description,
                    num_rib: p.num_rib,
                    bank_name: p.bank_name,
                    bank_agency: p.bank_agency,
                    num_pro_card: p.num_pro_card,
                    documents: p.documents,
                    region: p.region,
                    category_job_id: p.category_job_id,
                    min_call_allowed_per_sec: p.min_call_allowed_per_sec,
                    m_token: p.m_token,
                    state_payment_signup: p.state_payment_signup,
                    user_id: p.user_id,
                    updatedAt: p.updatedAt,
                    type: p.type,
                    transactions: p.transactions,
                    total_available: p.total_available,
                    total_win_pro: p.total_win_pro,
                    finished_signup: p.finished_signup
                }
            })} />
        </div>
    );
};


export default ProfessionnelsTable;
